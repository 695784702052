import React from "react";
import { graphql } from "gatsby";
import "./hero.scss";
import Phone from "../../images/phone.png";

export const query = graphql`
  query {
    file(relativePath: { eq: "hero-bg.jpg" }) {
      childImageSharp {
        fixed {
          height: height
          width: width
        }
      }
    }
  }
`;

const Hero = () => (
  <header className="hero">
    <div className="hero__img-div">
      <img src={Phone} />
    </div>

    <div className="hero__text">
      <iframe
        className="hero__video"
        src="https://www.youtube.com/embed/_Y9bXfwOBVc?autoplay=1&mute=1"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      <div>
        <h2>
          <span className="hero__header-text-C">c</span>
          reatives &nbsp;
          <span className="hero__header-text-N">n</span>
          ear &nbsp;
          <span className="hero__header-text-Y">u</span>
        </h2>
        <h3>
          Whether it’s music, film, photography, or anything in between, we
          strive to share our vision with the world. Sometimes the team you need
          is just steps away. Connect, Collab, and Create with them today.
        </h3>
      </div>
    </div>
  </header>
);

export default Hero;
