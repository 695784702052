import React, { useEffect } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

// Sections
import Hero from "../components/hero/hero";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Creatives Near You | Home" />
      <Hero />
      <section>
        <div className="feature-section">
          <div className="feature search">
            <div className="feature-img" />
            <h3>Search</h3>
            <p>
              for the creatives of any skill-set in your local area and for
              beyond.
            </p>
          </div>
          <div className="feature  connect">
            <div className="feature-img" />
            <h3>Connect</h3>
            <p>
              your content from external sources to create a consolidated
              creative portfolio.
            </p>
          </div>
          <div className="feature advertise">
            <div className="feature-img " />
            <h3>Advertise</h3>
            <p>
              your skills, services, and special events, while searching for
              opportunities around you.
            </p>
          </div>
          <div className="feature tag">
            <div className="feature-img" />
            <h3>Tag</h3>
            <p>
              the many creatives that contributed to your projects to direct
              others to connect with them.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
